import Vue from 'vue'
import VueRouter from 'vue-router'
import appHome from '../components/appHome.vue'
//import Keycloak from 'keycloak-js';
import AppConfig from '../../config.js';
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'appHome',
    component: appHome,
    meta:{
      isAuthNeeded: false,
      title: 'Home | Rasree'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "policy-chunk" */ '../pages/privacyPolicy.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Privacy Policy | Rasree'
    }
  },
  {
    path: '/terms-service',
    name: 'termsOfService',
    component: () => import(/* webpackChunkName: "policy-chunk" */ '../pages/termsOfService.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Terms of service | Rasree'
    }
  },
  {
    path: '/FAQ',
    name: 'faqSection',
    component: () => import(/* webpackChunkName: "policy-chunk" */ '../pages/faqSection.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'FAQ | Rasree'
    }
  },
  {
    path: '/what-we-offer',
    name: 'whatWeOfferSection',
    component: () => import(/* webpackChunkName: "policy-chunk" */ '../pages/whatWeOfferSection.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'what we offer | Rasree'
    }
  },
  {
    path: '/contact-us',
    name: 'contactUsSection',
    component: () => import(/* webpackChunkName: "policy-chunk" */ '../pages/contanctUsSection.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Contact us | Rasree'
    }
  },
  {
    path: '/me/primary_role',
    name: 'Primary-role',
    component: () => import(/* webpackChunkName: "survey-pr-role-chunk" */ '../components/Primary-role.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Primary Role | Rasree'
    }
  },
  {
    path: '/me/avatar',
    name: 'Avatar',
    component: () => import(/* webpackChunkName: "survey-avtr-chunk" */ '../components/userAvatar.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Avatar | Rasree'
    }
  },
  {
    path: '/me/personal_info',
    name: 'Personal-info',
    component: () => import(/* webpackChunkName: "survey-pr-info-chunk" */ '../components/Personal-info.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Personal Info | Rasree'
    }
  },
  {
    path: '/me/languages',
    name: 'Languages',
    component: () => import(/* webpackChunkName: "survey-lan-info-chunk" */ '../components/userLanguages.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/socials',
    name: 'Socials',
    component: () => import(/* webpackChunkName: "survey-social-info-chunk" */ '../components/userSocials.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/qualifications',
    name: 'Qualifications',
    component: () => import(/* webpackChunkName: "survey-qual-info-chunk" */ '../components/userQualifications.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/tutor_credentials',
    name: 'Tutor-credentials',
    component: () => import(/* webpackChunkName: "survey-tutr-cred-chunk" */ '../components/Tutor-credentials.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/sponsor_interest',
    name: 'Sponsor-interest',
    component: () => import(/* webpackChunkName: "survey-sp-intr-chunk" */ '../components/Sponsor-interest.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/protege_interest',
    name: 'Protege-interest',
    component: () => import(/* webpackChunkName: "survey-prot-intr-chunk" */ '../components/Protege-interest.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/tutor_privacy',
    name: 'Tutor-privacy',
    component: () => import(/* webpackChunkName: "survey-tutr-prvcy-chunk" */ '../components/Tutor-privacy.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/tutor_summary',
    name: 'Tutor-summary',
    component: () => import(/* webpackChunkName: "survey-tutr-sumry-chunk" */ '../components/Tutor-summary.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/protege_summary',
    name: 'Protege-summary',
    component: () => import(/* webpackChunkName: "survey-prot-sumry-chunk" */ '../components/Protege-summary.vue'),
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/me/sponsor_summary',
    name: 'Sponsor-summary',
    component: () => import(/* webpackChunkName: "survey-sp-sumry-chunk" */ '../components/Sponsor-summary.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Sponsor Summary | Rasree'
    }
  },
  {
    path: '/me/tutor_communication',
    name: 'Tutor-communication',
    component: () => import(/* webpackChunkName: "survey-tutr-cumn-chunk" */ '../components/Tutor-communication.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Tutor Communication | Rasree'
    }
  },
  {
    path: '/me/sponsor_communication',
    name: 'Sponsor-communication',
    component: () => import(/* webpackChunkName: "survey-spon-cumn-chunk" */ '../components/Sponsor-communication.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Sponsor Communication | Rasree'
    }
  },
  {
    path: '/me/protege_communication',
    name: 'Protege-communication',
    component: () => import(/* webpackChunkName: "survey-prot-cumn-chunk" */ '../components/Protege-communication.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Protege Communication | Rasree'
    }
  },
  {
    path: '/me/ward_management',
    name: 'Ward-manage',
    component: () => import(/* webpackChunkName: "survey-ward-mng-chunk" */ '../components/Ward-manage.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Ward Management | Rasree'
    }
  },
  {
    path: '/calendar-view',
    name: 'Calendar-view',
    component: () => import( '../views/Calendar-view.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Calendar | Rasree'
    }
  },
  {
    path: '/calendar/:id',
    name: 'userCalendar',
    component: () => import(/* webpackChunkName: "usr-cal-chunk" */ '../pages/userCalendar.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Calendar | Rasree'
    }
  },

  // {
  //   path: '/session/:slotID',
  //   name: 'slot_info',
  //   component: () => import(/* webpackChunkName: "app-chunk" */ '../pages/slot_info.vue'),
  //   meta:{
  //     isAuthNeeded: false,
  //     title: 'About Session | Rasree'
  //   }
  // },
  {
    path: '/session/:slotID',
    name: 'slot_info_v3',
    component: () => import(/* webpackChunkName: "slot-info-chunk" */ '../pages/slot_info_v3.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'About Session | Rasree'
    }
  },
  {
    path: '/learn/session/:slotID',
    name: 'liveSession',
    component: () => import(/* webpackChunkName: "live-slot-draft-chunk" */ '../pages/liveSession.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'live Session | Rasree'
    }
  },
  {
    path: '/learn/live/:slotID', // TODO: @soma netops
    name: 'liveSessionV3',
    component: () => import(/* webpackChunkName: "live-slot-chunk" */ '../pages/liveSessionV3.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'live Session | Rasree'
    }
  },
  // {
  //   path: '/course/:courseId',
  //   name: 'course_info',
  //   component: () => import(/* webpackChunkName: "course-info-draft-chunk" */ '../pages/course_info.vue'),
  //   meta:{
  //     isAuthNeeded: false,
  //     title: 'About Course | Rasree'
  //   }
  // },
  {
    path: '/course/:courseId',
    name: 'course_info_v3',
    component: () => import(/* webpackChunkName: "course-info-chunk" */ '../pages/course_info_v3.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'About Course | Rasree'
    }
  },
  {
    path: '/unsignedHome',
    name: 'unsignedHome',
    component: () => import(/* webpackChunkName: "home-chunk" */ '../pages/unsignedHome_page.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Unsigned Home | Rasree'
    }
  },
  {
    path: '/browseSearch',
    name: 'browseSearch',
    component: () => import(/* webpackChunkName: "explore-draft-chunk" */ '../pages/browse_search.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore | Rasree'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "explore-chunk" */ '../pages/browseSearch.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore | Rasree'
    }
  },
  {
    path: '/search-slot',
    name: 'searchSlot',
    component: () => import(/* webpackChunkName: "explore-slot-chunk" */ '../pages/browseSearchForSessions.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore slots | Rasree'
    }
  },
  {
    path: '/search-course',
    name: 'searchCourse',
    component: () => import(/* webpackChunkName: "explore-course-chunk" */ '../pages/browseSearchForCourses.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore courses | Rasree'
    }
  },
  {
    path: '/search-challenge',
    name: 'searchChallenge',
    component: () => import(/* webpackChunkName: "explore-chal-chunk" */ '../pages/browseSearchForChallenges.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore challenges | Rasree'
    }
  },
  {
    path: '/search-org',
    name: 'searchOrg',
    component: () => import(/* webpackChunkName: "explore-org-chunk" */ '../pages/browseSearchForOrgs.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore orgs | Rasree'
    }
  },
  {
    path: '/search-attempt',
    name: 'searchAttempt',
    component: () => import(/* webpackChunkName: "explore-atmpt-chunk" */ '../pages/browseSearchForAttempts.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore attempts | Rasree'
    }
  },
  {
    path: '/admin/exploreChallenges',
    name: 'exploreChallenges',
    component: () => import(/* webpackChunkName: "explore-admn-chal-chunk" */ '../pages/exploreChallenges.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Explore challenges | Rasree'
    }
  },
  {
    path: '/protege/exploreChallenges',
    name: 'protegeExploreChallenges',
    component: () => import(/* webpackChunkName: "explore-prot-chal-chunk" */ '../pages/protegeExploreChallenges.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Protege explore challenges | Rasree'
    }
  },

  {
    path: '/add-challenge-to-playlist-draft',
    name: 'AddChallengeToPlaylistDraft',
    component: () => import(/* webpackChunkName: "add-chal-plyst-draft-chunk" */ '../pages/exploreChallengesForPlaylist.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Add challenges to playlist | Rasree'
    }
  },

  {
    path: '/add-challenge-to-playlist',
    name: 'AddChallengeToPlaylist',
    component: () => import(/* webpackChunkName: "add-chal-plyst-chunk" */ '../pages/exploreChallengesForPlaylistDraft.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Add challenges to playlist | Rasree'
    }
  },

  // NOTE: under work@jay
  {
    path: '/get-user-playlist',
    name: 'SearchPlaylistDraft',
    component: () => import(/* webpackChunkName: "explore-playst-draft-chunk" */ '../pages/explorePlaylistForSessionDraft.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Add challenges to playlist | Rasree'
    }
  },

  {
    path: '/get-user-playlist-draft',
    name: 'SearchPlaylist',
    component: () => import(/* webpackChunkName: "explore-playst-chunk" */ '../pages/explorePlaylistForSession.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Add challenges to playlist | Rasree'
    }
  },
  // {
  //   path: '/playlist/:id',
  //   name: 'playlist',
  //   component: () => import(/* webpackChunkName: "plyst-chunk" */ '../pages/userPlaylist.vue'),
  //   meta:{
  //     isAuthNeeded: false,
  //     title: 'playlist | Rasree'
  //   }
  // },
  {
    path: '/playlist/:id',
    name: 'playlist_v3',
    component: () => import(/* webpackChunkName: "app-chunk" */ '../pages/userPlaylist_v3.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'playlist | Rasree'
    }
  },
  {
    path: '/editPlaylist/:id',
    name: 'editPlaylist',
    component: () => import(/* webpackChunkName: "edit-plyst-chunk" */ '../pages/editPlaylist.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Edit playlist | Rasree'
    }
  },

  // {
  //   path: '/add-playlist-to-session/:id',
  //   name: 'AddPlaylistToSession',
  //   component: () => import(/* webpackChunkName: "add-plyst-slot-draft-chunk" */ '../pages/AttachPlaylistToSession.vue'),
  //   meta:{
  //     isAuthNeeded: false,
  //     title: 'Add challenges to playlist | Rasree'
  //   }
  // },
  {
    path: '/add-playlist-to-session/:id',
    name: 'AddPlaylistToSessionV3',
    component: () => import(/* webpackChunkName: "add-plyst-slot-chunk" */ '../pages/AttachPlaylistToSession_v3.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Add challenges to playlist | Rasree'
    }
  },
  {
    path: '/edit-playlist-to-session/:id',
    name: 'editPlaylistToSession',
    component: () => import(/* webpackChunkName: "edit-plyst-slot-chunk" */ '../pages/updatePlaylistToSession.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'edit playlist to session | Rasree'
    }
  },
  {
    path: '/challenge/:id',
    name: 'challengeLanding',
    component: () => import(/* webpackChunkName: "chal-info-chunk" */ '../pages/challengeLanding.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Challenge landing | Rasree'
    }
  },
  // {
  //   path: '/attempt/:id',
  //   name: 'attemptChallenge',
  //   component: () => import(/* webpackChunkName: "atmpt-draft-chunk" */ '../pages/attemptChallenge.vue'),
  //   meta:{
  //     isAuthNeeded: true,
  //     title: 'Attempt Challenge | Rasree'
  //   }
  // },
  {
    path: '/attempt/:id',
    name: 'attemptChallenge_v3',
    component: () => import(/* webpackChunkName: "app-chunk" */ '../pages/attemptChallenge_v3.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Attempt Challenge | Rasree'
    }
  },
  {
    path: '/view-attempt/:id',
    name: 'viewAttempt',
    component: () => import(/* webpackChunkName: "view-atmpt-info-chunk" */ '../pages/viewAttempt.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'View attempt | Rasree'
    }
  },
  {
    path: '/courseProtege/:id',
    name: 'courseProtege',
    component: () => import(/* webpackChunkName: "course-prot-chunk" */ '../pages/courseProtege.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'courseProtege | Rasree'
    }
  },
  {
    path: '/congrats',
    name: 'userCongrats',
    component: () => import(/* webpackChunkName: "usr-congts-chunk" */ '../pages/userCongrats.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'congrats | Rasree'
    }
  },
  {
    path: '/dashBoard',
    name: 'dashBoard',
    component: () => import(/* webpackChunkName: "dash-draft-chunk" */ '../pages/dashBoard.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Dashboard | Rasree'
    }
  },
  {
    path: '/tutor-dash/orgs/:id',
    name: 'tutorDash',
    component: () => import(/* webpackChunkName: "tutor-dash-info-chunk" */ '../pages/tutorDash.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Tutor Dashboard | Rasree'
    }
  },

  {
    path: '/admin/dashBoard',
    name: 'adminDashBoard',
    component: () => import(/* webpackChunkName: "admin-dash-draft-chunk" */ '../pages/adminDashBoard.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Admin Dashboard | Rasree'
    }
  },
  {
    path: '/manage/sponsor',
    name: 'manageSponsor',
    component: () => import(/* webpackChunkName: "mng-sp-chunk" */ '../pages/manageSponsor.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage sponsor | Rasree'
    }
  },
  {
    path: '/manage/sponsorInvitation/:id',
    name: 'manageSponsorInvitation',
    component: () => import(/* webpackChunkName: "mng-sp-inv-chunk" */ '../pages/manageSponsorInvitation.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage sponsor invitation | Rasree'
    }
  },
  {
    path: '/manage/wardInvitation/:id',
    name: 'manageWardInvitation',
    component: () => import(/* webpackChunkName: "mng-wrd-inv-chunk" */ '../pages/manageWardInvitation.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage ward invitation | Rasree'
    }
  },
  {
    path: '/manage/tutorInvitation/:id',
    name: 'manageTutorInvitation',
    component: () => import(/* webpackChunkName: "mng-tutr-inv-chunk" */ '../pages/manageTutorInvitation.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage tutor invitation | Rasree'
    }
  },
  {
    path: '/manage/orgInvitation/:id',
    name: 'manageOrgInvitation',
    component: () => import(/* webpackChunkName: "mng-org-inv-chunk" */ '../pages/manageOrgInvitation.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage org invitation | Rasree'
    }
  },
  {
    path: '/manage/ward',
    name: 'manageWard',
    component: () => import(/* webpackChunkName: "mng-wrd-chunk" */ '../pages/manageWard.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage ward | Rasree'
    }
  },
  {
    path: '/manage-user',
    name: 'user-control',
    component: () => import(/* webpackChunkName: "mng-usr-chunk" */ '../pages/userControl.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'User control | Rasree'
    }
  },

  {
    path: '/super',
    name: 'manage-user-control',
    component: () => import(/* webpackChunkName: "app-chunk" */ '../pages/userControlV3.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'User control | Rasree'
    }
  },



  {
    path: '/orgs/new',
    name: 'CreateOrg',
    component: () => import(/* webpackChunkName: "new-org-chunk" */ '../pages/createOrg.vue') // Assuming the component's path
  },
  {
    path: '/orgs/edit/:id',
    name: 'editOrg',
    component: () => import(/* webpackChunkName: "edit-org-info-chunk" */ '../pages/editOrg.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'edit Org | Rasree'
    }
  },{
    path: '/orgs/my',
    name: 'manageOrgs',
    component: () => import(/* webpackChunkName: "own-orgs-chunk" */ '../pages/manageOrgs.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage orgs | Rasree'
    }
  },
  {
    path: '/orgs/tutors',
    name: 'manageOrgTutors',
    component: () => import(/* webpackChunkName: "mng-tutrs-chunk" */ '../pages/manageOrgTutors.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Manage org tutors | Rasree'
    }
  },
  {
    path: '/orgs/:id',
    name: 'orglanding',
    component: () => import(/* webpackChunkName: "org-info-chunk" */ '../pages/orgLandingPage.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'Org Landing | Rasree'
    }
  },

  {
    path: '/notifications',
    name: 'notificationsView',
    component: () => import(/* webpackChunkName: "ntf-info-chunk" */ '../pages/notificationsView.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Notifications | Rasree'
    }
  },
  // TODO : create_course_draft.vue is using org_id as per req of new flow , so remove create_course.vue and rename 
  // create_course_draft.vue to create_course.vue
  {
    path: '/course-create',
    name: 'createCourse',
    component: () => import(/* webpackChunkName: "crt-course-draft-chunk" */ '../pages/create_course.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create Course | Rasree'
    }
  },
  // TODO: change URL : path: '/org/:org_id/create-course',
  {
    path: '/org/:org_id/create-course',
    name: 'createCourseDraft',
    component: () => import(/* webpackChunkName: "crt-course-chunk" */ '../pages/create_course_draft.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create Course | Rasree'
    }
  },



  {
    path: '/admin/createChallenge',
    name: 'createChallenge',
    component: () => import(/* webpackChunkName: "crt-chal-chunk" */ '../pages/create_challenge.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create Challenge | Rasree'
    }
  },

  // TODO : edit_course_draft.vue is  as per req of new flow , so remove edit_course.vue and rename 
  // edit_course_draft.vue to edit_course.vue


  {
    path: '/course-edit-draft/:courseID',
    name: 'editCourse',
    component: () => import(/* webpackChunkName: "edit-course-draft-chunk" */ '../pages/edit_course.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Course | Rasree'
    }
  },
  // {
  //   path: '/course-edit/:courseID',
  //   name: 'editCourseDraft',
  //   component: () => import(/* webpackChunkName: "edit-course-old-draft-chunk" */ '../pages/edit_course_draft.vue'),
  //   meta:{
  //     isAuthNeeded: true,
  //     title: 'Edit Course | Rasree'
  //   }
  // },
  {
    path: '/course-edit/:courseID',
    name: 'editCourseDraftV3',
    component: () => import(/* webpackChunkName: "edit-course-chunk" */ '../pages/edit_course_draft_v3.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Course | Rasree'
    }
  },



  {
    path: '/challengeEdit/:id',
    name: 'challenge_creation',
    component: () => import(/* webpackChunkName: "edit-chal-draft-chunk" */ '../pages/challenge_creation.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Challenge | Rasree'
    }
  },
  {
    path: '/challengeView/:id',
    name: 'challengeView',
    component: () => import(/* webpackChunkName: "view-chal-draft-chunk" */ '../pages/view_challenge.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Challenge | Rasree'
    }
  },
  {
    path: '/self-session-create', 
    name: 'selfCreateSession',
    component: () => import(/* webpackChunkName: "self-slot-info-chunk" */ '../pages/selfCreateSession.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create Session Self | Rasree'
    }
  },
  {
    path: '/quick-adhoc-slot', 
    name: 'createQuickAdhocSlot',
    component: () => import(/* webpackChunkName: "quick-slot-chunk" */ '../pages/createQuickAdhocSlot.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create quick slot | Rasree'
    }
  },
  {
    path: '/session-create', ///:courseId, sessions are initally independent of courses
    name: 'createSession',
    component: () => import(/* webpackChunkName: "crt-slot-chunk" */ '../pages/createSession.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create Session | Rasree'
    }
  },
  {
    path: '/adhoc-session-create', ///:courseId, sessions are initally independent of courses
    name: 'createAdhocSessionForProtege',
    component: () => import(/* webpackChunkName: "tutr-adhoc-slot-chunk" */ '../pages/tutorAdhocSlot.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Create  adhoc session | Rasree'
    }
  },
  // {
  //   path: '/session-edit/:sessionId',
  //   name: 'editSession',
  //   component: () => import(/* webpackChunkName: "slot-edit-draft-chunk" */ '../pages/edit_session_draft.vue'),
  //   meta:{
  //     isAuthNeeded: true,
  //     title: 'Edit Session | Rasree'
  //   }
  // },
  {
    path: '/session-edit/:sessionId',
    name: 'editSession_v3',
    component: () => import(/* webpackChunkName: "edit-slot-chunk" */ '../pages/edit_session_draft_v3.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Session | Rasree'
    }
  },
  {
    path: '/session-edit-draft/:sessionId',
    name: 'editSessionDraft',
    component: () => import(/* webpackChunkName: "edit-slot-draft-old-chunk" */ '../pages/edit_session.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Session | Rasree'
    }
  },
  {
    path: '/talk/:sessionId',
    name: 'meetSession',
    component: () => import(/* webpackChunkName: "meet-talk-chunk" */ '../pages/meetSession.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Meet Session | Rasree'
    }
  },
  {
    path: '/admin/viewChallenges',
    name: 'view-Challenges',
    component: () => import(/* webpackChunkName: "admin-view-chal-draft-chunk" */ '../pages/viewChallengesList.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'view Challenges | Rasree'
    }
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: () => import(/* webpackChunkName: "chal-draft-old-chunk" */ '../views/challengesComp.vue'),
    children:[
      {
        path: ':tags',
        component: () => import(/* webpackChunkName: "app-chunk" */ '../views/challengesComp.vue'),
        meta:{
          isAuthNeeded: true,
          title: 'Explore Challenges | Rasree'
        },
      },
    ],
    meta:{
      isAuthNeeded: true,
      title: ' Explore Challenges | Rasree'
    }
  },
  {
    path: '/admin/editChallenge/:id',
    name: 'Edit-Challenge',
    component: () => import(/* webpackChunkName: "edit-chal-chunk" */ '../pages/edit_challenge.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Challenge | Rasree'
    }
  },
  {
    path: '/profile/draft',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile-draft-chunk" */ '../pages/userProfile.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Profile | Rasree'
    }
  },

  {
    path: '/create-playlist',
    name: 'createPlaylist',
    component: () => import(/* webpackChunkName: "crt-plyst-chunk" */ '../pages/createPlaylist.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Playlist | Rasree'
    }
  },


  {
    path: '/profile',
    name: 'userProfileSample',
    component: () => import(/* webpackChunkName: "usr-prfil-sample-chunk" */ '../pages/userProfileSample.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Profile | Rasree'
    }
  },
  {
    path: '/user/:id',
    name: 'userProfileComp',
    component: () => import(/* webpackChunkName: "user-profile-sample-chunk" */ '../pages/userProfileComp.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'User info | Rasree'
    }
  },
  {
    path: '/profile/edit',
    name: 'editProfile',
    component: () => import(/* webpackChunkName: "edit-profile-chunk" */ '../components/editProfile.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Profile | Rasree'
    }
  },
  {
    path: '/profile/emails',
    name: 'emailSetting',
    component: () => import(/* webpackChunkName: "email-profile-chunk" */ '../components/emailSetting.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Email | Profile | Rasree'
    }
  },
  {
    path: '/profile/password/edit',
    name: 'editPassword',
    component: () => import(/* webpackChunkName: "password-edit-chunk" */ '../components/editPassword.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Password | Profile | Rasree'
    }
  },
  {
    path: '/profile/edit_qual',
    name: 'editQual',
    component: () => import(/* webpackChunkName: "qual-edit-chunk" */ '../components/editQual.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Edit Qual | Profile | Rasree'
    }
  },
  {
    path: '/viewChallenge/:id',
    name: 'viewChallenge',
    component: () => import(/* webpackChunkName: "view-chal-chunk" */ '../components/viewChallenge.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Challenge | Rasree'
    }
  },
  {
    path: '/viewMyChallenge/:id',
    name: 'viewMyChallenge',
    component: () => import(/* webpackChunkName: "view-own-chal-chunk" */ '../components/viewMyChallenge.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'My Challenges | Rasree'
    }
  },
  {
    path: '/abhyasCanvas/:name',
    name: 'abhyasCanvas',
    component: () => import(/* webpackChunkName: "abyas-canvs-chunk" */ '../organisms/abhyasCanvas.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'Canvas | Rasree'
    }
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: () => import(/* webpackChunkName: "acc-denied-chunk" */ '../pages/accessDenied.vue'),
    meta:{
      isAuthNeeded: true,
      title: 'access denied | Rasree'
    }
  },
  {
    path: '/maintenance',
    name: 'userMaintenance',
    component: () => import(/* webpackChunkName: "acc-denied-chunk" */ '../pages/UserMaintenance.vue'),
    meta:{
      isAuthNeeded: false,
      title: 'User maintenance | Rasree'
    }
  },
  {
    path: '/login',
    meta:{
      isAuthNeeded: true,
      title: 'Login | Rasree'
    }
  },
  {
    path: '/logout',
    meta:{
      isAuthNeeded: true,
      title: 'Logout | Rasree'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'is-active',
  scrollBehavior() {
    // here, we mounted VueJs app to #app id, so we are using it for scroll-to-top on route navigations.
    document.getElementById('app').scrollIntoView({behavior:'smooth'});
  }
})

router.beforeEach((to, from, next) => {
    console.log("to url in index js is", to);

    // set title of the page
    if(to.meta.title!== undefined){
      document.title = to.meta.title
    }
  
    // Get the base url of the app, it's needed for Keycloak
    const basePath = AppConfig.UI_Base_URL
    console.log("base url", basePath)
  
    // create redirect url
    let redirect_to = basePath + to.fullPath
    if(redirect_to.includes("login")){ // TODO: fix this, but check if we are using it or not before removing
      // as in case of some page we first force to go to login page and so 'to' here becomes the login and not the actual page
      redirect_to = basePath
    }

    // check if auth needed or not for the page
    if (to.meta.isAuthNeeded) {
      if (!Vue.$keycloak.authenticated) {
        // The page is protected and the user is not authenticated. Force a login.
        Vue.$keycloak.login({ redirectUri: redirect_to })
      }
    }
    if(Vue.$keycloak.authenticated){
        setInterval(() => {
            Vue.$keycloak.updateToken(70).then((refreshed) => {
                if (refreshed) {
                console.log('Token refreshed' + refreshed);
                } /*else {
                console.log('Token not refreshed, valid for '
                    + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                }*/
            }).catch(() => {
                console.log('Failed to refresh token');
            });
        }, 6000)
      console.log("----------only execute if user is logged in");
      localStorage.setItem('access-token', Vue.$keycloak.token);
      localStorage.setItem('refresh-token', Vue.$keycloak.refreshToken);
      localStorage.setItem('id-token', Vue.$keycloak.idToken);
      axios.defaults.headers.common['Authorization'] = "Bearer " + Vue.$keycloak.token
      axios.defaults.headers.common['id-token'] = "Bearer " + Vue.$keycloak.idToken
      axios.defaults.headers.common['access-token'] = "Bearer " + Vue.$keycloak.token
      axios.defaults.headers.common['refresh-token'] = "Bearer " + Vue.$keycloak.refreshToken
      axios.defaults.headers.common['Access-Control-Max-Age'] = 86400
    }
    next()
  })

export default router