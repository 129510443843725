<template>
  <v-app >
    <c-top-nav-bar v-if="(showTopNavBar)" :isAuth="isAuth" :roleOfUser="roleOfUser"></c-top-nav-bar>
    <router-view v-if="showFloatingNavBar" :isAuth="isAuth"  :roleOfUser="roleOfUser" style="margin-top: 81px;" />
    <router-view v-if="!showFloatingNavBar" :isAuth="isAuth"  :roleOfUser="roleOfUser" />
  </v-app>
</template>
  
<script>
import topNavBarVue from './organisms/topNavBar.vue';
import axios from 'axios';
import AppConfig from '../config';
export default {
  name: 'App',
  components: {
    'c-top-nav-bar': topNavBarVue
  },
  data(){
    return{
      isAuth : false,
      roleOfUser : "",
      //UI_baseURL: AppConfig.UI_Base_URL,
      auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
      showTopNavBar: true,
      showFloatingNavBar: true
    }
  },
  async created(){
    this.isAuth = this.$keycloak.authenticated
    await this.checkUserAuthFn()
    let routePathName = window.location.pathname
    // let liveSessionPathName = '/learn/session/'
    let liveSessionV3PathName = '/learn/live/'
    if(routePathName.includes(liveSessionV3PathName) || routePathName.includes("/talk/") || ((routePathName === '/') && !(this.isAuth))){
      this.showTopNavBar = false
    }
    this.HandleFloatingTopNavBar()
    await this.primaryRoleOfUser()
  },
  methods :{
    HandleFloatingTopNavBar(){
      let routePathName = window.location.pathname
      if((routePathName === '/') && !(this.isAuth)){
        this.showFloatingNavBar = false;
      }
      else{
        this.showFloatingNavBar = true
      }
    },
    /**
     * 
     * @deprecated This function is deprecated and it can be removed.
     * We are not using this API calls any more
     */
    async primaryRoleOfUser(){
      //get role of user from the API
      try{
          let allRolesResp = await axios.get(this.auth_api_Base_URL + "/v1_0/me/primary_role")
          console.log("get primary role", allRolesResp)
          this.roleOfUser = allRolesResp.data.primary_role
          console.log("-------user role --------",this.roleOfUser);
      }catch(error){
          console.log("error in resp pf primary role:", error.response)
      }
    },
    async checkUserAuthFn(){
      //check if user is authenticated or not and also check teh crole of user
      //use this info to hide or show some section of UI
      try{
        let resp = await axios.get(this.auth_api_Base_URL + "/v1_0/me")
        console.log("get user resp", resp)
        if(resp.status === 200){
          this.isAuth = true
          //await this.primaryRoleOfUser()
        }
        else{
            this.isAuth = false
        }
        console.log("-------userAuthValue-----", this.isAuth);
      }catch(error){
        console.log("error in resp of getting user info:", error.response)
      }
    }
  }
}
</script>

<style>
@import './main.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #F8FFFF;
}
</style>
