<template>
  <c-top-navbar v-if="defaultNavBar">
    <c-navBar-desktop id="nav-desktop">
      <c-logo-div style="cursor: pointer">
        <!--<router-link to="/" id="inputHomeIcon">
                    <img src="../assets/rasree-logo.png" alt="" style="width:220px;height:57px;">
                </router-link>-->
        <img
          src="../assets/images/logo-name-blue.png"
          alt=""
          style="width: 220px; height: 57px"
          @click="gotoHomePageFn()"
        />
      </c-logo-div>
      <c-search-bar>
        <c-button-navlink
          prependIcon="text_snippet"
          id="inputExploreBtn"
          @click.native="navigateToBrowseSearchFn()"
          >Explore</c-button-navlink
        >
        <c-search-box-div>
          <c-input-search-box
            id="inputSearchBarDefault"
            placeholder="Search for subjects, concepts, tutors, etc."
            v-model="inputSearchedTerm"
            @keyup.enter="gotoBrowseSearchFn(inputSearchedTerm)"
          ></c-input-search-box>
          <c-style-icon
            id="inputClickOnSearchItem"
            styledIcon="zoom_in"
            style="cursor: pointer"
            @click.native="gotoBrowseSearchFn(inputSearchedTerm)"
          ></c-style-icon>
        </c-search-box-div>
      </c-search-bar>
      <c-style-icon
        v-if="isAuth"
        iconType="outlined"
        id="inputCalendar"
        styledIcon="calendar_today"
        style="cursor: pointer; order: 1"
        @click.native="gotoCalendarFn()"
      ></c-style-icon>
      <div
        v-if="isAuth"
        class="notification-bell"
        @click="toggleAlertMenuModalFn()"
      >
        <c-style-icon
          iconType="outlined"
          id="inputNotifications"
          styledIcon="notifications"
          style="cursor: pointer; order: 1"
        ></c-style-icon>
        <span class="notification-count" v-if="notifications.length > 0">{{
          notifications.length
        }}</span>
      </div>
      <c-sign-in-buttons-div v-if="!isAuth">
        <c-button-secondary
          @click.native="gotoSignedHomeFn"
          id="inputSignInButton"
          >Sign in</c-button-secondary
        >
        <!-- <c-button-primary id="inputSignUpButton" data-tf-popup="01J7YY5RRM24F20YTDYHT52FNG" data-tf-opacity="100" data-tf-size="100" data-tf-iframe-props="title=Enrollment Request" data-tf-transitive-search-params data-tf-medium="snippet" @click.native="enrollFn">Sign up</c-button-primary> -->
        <!-- <c-button-primary id="inputSignUpButton" data-tf-medium="snippet"  data-tf-live="01J7YY5RRM24F20YTDYHT52FNG" @click.native="enrollFn">Sign up</c-button-primary> -->
        <!-- <c-button-primary id="inputSignUpButton" data-tf-medium="snippet"  data-tf-live="01J7YY5RRM24F20YTDYHT52FNG" style="color:white !important;">Sign up</c-button-primary> -->
        <c-button-primary
          id="inputSignUpButton"
          @click.native="openBetaInviteFormFn"
          >Sign up</c-button-primary
        >
        <c-beta-invite-form ref="betaInviteForm"
        ></c-beta-invite-form>
        <!--<c-row>
                    <button data-tf-popup="hGMuf6o2" data-tf-opacity="100" data-tf-size="100" data-tf-iframe-props="title=Enrollment Request" data-tf-transitive-search-params data-tf-medium="snippet" style="all:unset;font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;max-height:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#00B0B2;color:#fff;font-size:18px;border-radius:5px;padding:0 30px;font-weight:bold;height:45px;cursor:pointer;line-height:45px;text-align:center;margin:0;text-decoration:none;" @click="enrollFn">Sign up</button>
                </c-row>-->
      </c-sign-in-buttons-div>
      <c-profile-div
        v-if="isAuth && this.$route.path != '/privacy-policy'"
        v-on:click="collapsed = !collapsed"
        @click.native="getProfileMenuViewFn()"
        id="profileBtn"
      >
        <img
          :src="userImage || defaultImage"
          alt=""
          style="width: 40px; height: 40px; border-radius: 20%"
        />
        <c-H4-22>{{ username }}</c-H4-22>
        <c-style-icon
          id="inputToggleicon"
          :styledIcon="collapsed ? 'expand_more' : 'expand_less'"
        ></c-style-icon>
        <div id="inputProfileMenuView" class="profilemodal">
          <c-row
            class="profile-menu-view"
            style="height: max-content; display: block; overflow: unset"
          >
            <c-name-div
              ><c-overline-11>{{ username }}</c-overline-11></c-name-div
            >
            <c-list-item
              style="width: auto"
              prependIcon="person"
              v-if="
                roleOfUser === 'Admin' ||
                roleOfUser === 'Auditor' ||
                roleOfUser === 'Protege' ||
                roleOfUser === 'Tutor'
              "
              @click.native="navigateToProfilePageFn"
              id="inputProfileBtn"
              >Profile</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="calendar_today"
              v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
              id="inputMycoursesBtn"
              @click.native="myCoursesFn"
              >My Courses</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="schedule"
              v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
              id="inputMySessionsBtn"
              @click.native="mySessionsFn"
              >My Sessions</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="group"
              v-if="
                roleOfUser === 'Protege' ||
                roleOfUser === 'Tutor' ||
                roleOfUser === 'Admin'
              "
              id="inputManageSponsorBtn"
              @click.native="manageSposorFn"
              >Manage sponsor</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="group"
              v-if="
                roleOfUser === 'Protege' ||
                roleOfUser === 'Tutor' ||
                roleOfUser === 'Admin'
              "
              id="inputManageWardBtn"
              @click.native="manageWardFn"
              >Manage ward</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="swap_horiz"
              v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
              id="inputMyChallengesBtn"
              @click.native="myChallengesFn"
              >My Challenges</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="tune"
              id="inputManageOrgBtnForAdmindashboard"
              @click.native="manageOrgFn"
              >Manage Orgs</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="group"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName === '/admin/dashBoard'
              "
              id="inputManageUsersBtnForAdmindashboard"
              @click.native="manageUsersFn"
              >Manage Users</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="group"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName != '/admin/dashBoard' &&
                routePathName != '/dashBoard'
              "
              id="inputManageUsersBtnForAdmin"
              @click.native="manageUsersFn"
              >Manage Users</c-list-item
            >
            <!--<c-list-item  style="width:auto" prependIcon="swap_horiz" v-if="(roleOfUser==='Admin' || roleOfUser==='Auditor'  || roleOfUser==='Tutor') && (routePathName != '/admin/dashBoard' && routePathName != '/dashBoard') " id="inputSwitchDashBoardbtn" @click.native="switchToLearnerDashBoardFn">Learner Dashboard</c-list-item>-->
            <c-list-item
              style="width: auto"
              prependIcon="calendar_today"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName === '/dashBoard'
              "
              id="inputMycourseBtnForLearnerDashBoard"
              @click.native="myCoursesFn"
              >My Courses</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="schedule"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName === '/dashBoard'
              "
              id="inputMysessionsBtnForLearnerDashBoard"
              @click.native="mySessionsFn"
              >My Sessions</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="swap_horiz"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName === '/dashBoard'
              "
              id="inputMyChallengesBtnForLearnerDashBoard"
              @click.native="myChallengesFn"
              >My Challenges</c-list-item
            >
            <!--<c-list-item  style="width:auto" prependIcon="swap_horiz" v-if="(roleOfUser==='Admin' || roleOfUser==='Auditor') && routePathName === '/admin/dashBoard' " id="inputSwitchAdminDashBoardbtnForLearnerDashboard" @click.native="switchToLearnerDashBoardFn">Learner Dashboard</c-list-item>-->
            <c-list-item
              style="width: auto"
              prependIcon="swap_horiz"
              v-if="
                (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
                routePathName === '/dashBoard'
              "
              id="inputSwitchAdminDashBoardbtn"
              @click.native="switchToAdminDashBoardFn"
              >Admin Dashboard</c-list-item
            >
            <c-list-item
              style="width: auto"
              prependIcon="logout"
              @click.native="logoutFn"
              id="inputLogoutBtnForAdmin"
              >Log-out</c-list-item
            >
          </c-row>
        </div>
      </c-profile-div>
      <div
        id="input-alert-menu-modal"
        style="
          display: none;
          position: fixed;
          z-index: 1;
          padding-top: 100px;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
        "
      >
        <div
          id="input-alert-menu-view"
          style="
            background-color: #f8ffff;
            border: 1px solid #d2e7e7;
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            display: block;
            right: 0px;
            width: 40%;
            position: fixed;
            right: 15%;
            top: 87px;
          "
        >
          <div id="ntf-title-div">
            <h2 style="display: inline-block">Notifications</h2>
            <button style="float: right" @click="toggleAlertMenuModalFn()">
              X
            </button>
            <!-- Replace with your cancel icon -->
          </div>
          <div id="ntf-list-div">
            <div
              style="cursor: pointer"
              v-for="notification in notifications.slice().reverse()"
              :key="notification.id"
              :id="`notification-${notification.id}`"
              @mouseleave="leaveEffect"
              @click="navigateToInvitationPage(notification)"
            >
              • {{ notification.notf_message }}
            </div>
          </div>
          <div id="ntf-view-more-div" style="text-align: right">
            <button
              style="
                font-family: Merriweather Sans;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                color: #00b0b2;
                flex: none;
                order: 9;
                align-self: stretch;
                flex-grow: 1;
              "
              @click="viewAllNotificationsFn"
            >
              View all notifications
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          id="input-urgent-alert-menu-modal"
          style="
            display: none;
            position: fixed;
            z-index: 1;
            padding-top: 100px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgb(0, 0, 0);
            background-color: rgba(0, 0, 0, 0.4);
          "
        >
          <div
            id="input-urgent-alert-menu-view"
            style="
              background-color: #f8ffff;
              border: 1px solid #d2e7e7;
              box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
              border-radius: 8px;
              display: block;
              right: 0px;
              width: auto;
              position: fixed;
              right: 10%;
              top: 87px;
            "
          >
            <div id="ntf-title-div">
              <h2 style="display: inline-block">Urgent alert</h2>
              <!--<button style="float: right;" @click="toggleUrgentAlertMenuModalFn()">X</button>-->
              <!-- Replace with your cancel icon -->
            </div>
            <div id="ntf-list-div">
              <div
                style="cursor: pointer; padding: 16px"
                @click="navigateToConfirmPage(urgentNofiticationInfo)"
              >
                {{ urgentNofiticationInfo.notf_message }}
              </div>
            </div>
            <c-frame-16
              style="
                flex-direction: row;
                justify-content: flex-end;
                width: 100%;
              "
            >
              <c-button-primary
                @click.native="navigateToConfirmPage(urgentNofiticationInfo)"
                >View</c-button-primary
              >
              <c-button-secondary @click.native="closeUrgentPopUpModalFn()"
                >Cancel</c-button-secondary
              >
            </c-frame-16>
          </div>
        </div>
      </div>
    </c-navBar-desktop>
    <c-navBar-mobile id="nav-mobile">
      <c-logo-div style="width: 100px; height: auto; cursor: pointer">
        <img
          src="../assets/carousel-images/logo/color-logo-3-micro-rounded.png"
          alt=""
          style="width: 40px; height: 40px"
          @click="gotoHomePageFn()"
        />
      </c-logo-div>
      <c-search-bar style="width: auto">
        <c-search-box-div>
          <c-input-search-box
            style="width: 150px"
            id="inputSearchBarMinified"
            placeholder="Search here.."
            v-model="inputSearchedTerm"
            @keyup.enter="gotoBrowseSearchFn(inputSearchedTerm)"
          ></c-input-search-box>
          <c-style-icon
            id="inputClickOnSearchItem"
            styledIcon="zoom_in"
            style="cursor: pointer"
            @click.native="gotoBrowseSearchFn(inputSearchedTerm)"
          ></c-style-icon>
        </c-search-box-div>
      </c-search-bar>
      <c-style-icon
        v-if="isAuth"
        iconType="outlined"
        id="inputCalendar"
        styledIcon="calendar_today"
        style="cursor: pointer; order: 1"
        @click.native="gotoCalendarFn()"
      ></c-style-icon>
      <div
        v-if="isAuth"
        class="notification-bell"
        @click="toggleAlertMenuModalFn()"
      >
        <c-style-icon
          iconType="outlined"
          id="inputNotifications"
          styledIcon="notifications"
          style="cursor: pointer; order: 1"
        ></c-style-icon>
        <span class="notification-count" v-if="notifications.length > 0">{{
          notifications.length
        }}</span>
      </div>
      <c-style-icon
        styledIcon="person"
        @click.native="getProfileMenuViewFn()"
        style="order: 3"
      ></c-style-icon>
      <div id="inputProfileMenuView" class="profilemodal">
        <c-row
          v-if="!isAuth"
          class="profile-menu-view"
          style="height: max-content; display: block"
        >
          <c-list-item
            prependIcon="person"
            @click.native="gotoSignedHomeFn"
            id="inputProfileBtn"
            >Sign in</c-list-item
          >
          <c-list-item
            prependIcon="calendar_today"
            id="inputMycoursesBtn"
            @click.native="enrollFn"
            >Sign up</c-list-item
          >
        </c-row>
        <c-row
          v-if="isAuth"
          class="profile-menu-view"
          style="height: max-content; display: block; overflow: unset"
        >
          <c-name-div
            ><c-overline-11>{{ username }}</c-overline-11></c-name-div
          >
          <c-list-item
            style="width: auto"
            prependIcon="person"
            v-if="
              roleOfUser === 'Admin' ||
              roleOfUser === 'Auditor' ||
              roleOfUser === 'Protege' ||
              roleOfUser === 'Tutor'
            "
            @click.native="navigateToProfilePageFn"
            id="inputProfileBtn"
            >Profile</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="calendar_today"
            v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
            id="inputMycoursesBtn"
            @click.native="myCoursesFn"
            >My Courses</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="schedule"
            v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
            id="inputMySessionsBtn"
            @click.native="mySessionsFn"
            >My Sessions</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="group"
            v-if="
              roleOfUser === 'Protege' ||
              roleOfUser === 'Tutor' ||
              roleOfUser === 'Admin'
            "
            id="inputManageSponsorBtn"
            @click.native="manageSposorFn"
            >Manage sponsor</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="group"
            v-if="
              roleOfUser === 'Protege' ||
              roleOfUser === 'Tutor' ||
              roleOfUser === 'Admin'
            "
            id="inputManageWardBtn"
            @click.native="manageWardFn"
            >Manage ward</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="swap_horiz"
            v-if="roleOfUser === 'Protege' || roleOfUser === 'Tutor'"
            id="inputMyChallengesBtn"
            @click.native="myChallengesFn"
            >My Challenges</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="tune"
            id="inputManageOrgBtnForAdmindashboard"
            @click.native="manageOrgFn"
            >Manage Org</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="group"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName === '/admin/dashBoard'
            "
            id="inputManageUsersBtnForAdmindashboard"
            @click.native="manageUsersFn"
            >Manage Users</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="group"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName != '/admin/dashBoard' &&
              routePathName != '/dashBoard'
            "
            id="inputManageUsersBtnForAdmin"
            @click.native="manageUsersFn"
            >Manage Users</c-list-item
          >
          <!--<c-list-item style="width:auto" prependIcon="swap_horiz" v-if="(roleOfUser==='Admin' || roleOfUser==='Auditor'  || roleOfUser==='Tutor') && (routePathName != '/admin/dashBoard' && routePathName != '/dashBoard') " id="inputSwitchDashBoardbtn" @click.native="switchToLearnerDashBoardFn">Learner Dashboard</c-list-item>-->
          <c-list-item
            style="width: auto"
            prependIcon="calendar_today"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName === '/dashBoard'
            "
            id="inputMycourseBtnForLearnerDashBoard"
            @click.native="myCoursesFn"
            >My Courses</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="schedule"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName === '/dashBoard'
            "
            id="inputMysessionsBtnForLearnerDashBoard"
            @click.native="mySessionsFn"
            >My Sessions</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="swap_horiz"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName === '/dashBoard'
            "
            id="inputMyChallengesBtnForLearnerDashBoard"
            @click.native="myChallengesFn"
            >My Challenges</c-list-item
          >
          <!--<c-list-item style="width:auto" prependIcon="swap_horiz" v-if="(roleOfUser==='Admin' || roleOfUser==='Auditor') && routePathName === '/admin/dashBoard' " id="inputSwitchAdminDashBoardbtnForLearnerDashboard" @click.native="switchToLearnerDashBoardFn">Learner Dashboard</c-list-item>-->
          <c-list-item
            style="width: auto"
            prependIcon="swap_horiz"
            v-if="
              (roleOfUser === 'Admin' || roleOfUser === 'Auditor') &&
              routePathName === '/dashBoard'
            "
            id="inputSwitchAdminDashBoardbtn"
            @click.native="switchToAdminDashBoardFn"
            >Admin Dashboard</c-list-item
          >
          <c-list-item
            style="width: auto"
            prependIcon="logout"
            @click.native="logoutFn"
            id="inputLogoutBtnForAdmin"
            >Log-out</c-list-item
          >
        </c-row>
      </div>
    </c-navBar-mobile>
  </c-top-navbar>
</template>
<script>
import axios from "axios";
import { theme } from "../assets/theme.js";
import style_icons from "../molecules/style_icons.vue";
import {
  navBarDesktop,
  navBarMobile,
  frame_16,
  nameDiv,
  profileDiv,
  inputSearchBox,
  searchBoxDiv,
  signInButtonsDiv,
  topNavBarDiv,
  logoDiv,
  searchBar,
  rowDiv,
} from "../atoms/div_styles";
import { H4_22, OVERLINE_11 } from "../atoms/text_styles";
import listItems from "../molecules/listItems.vue";
import button_secondary from "../molecules/button_secondary.vue";
import button_primary from "../molecules/button_primary.vue";
import button_navlink from "../molecules/button_navlink.vue";
import AppConfig from "../../config";
import profileImage from "../assets/images/profileImage.png";
//import rcApi from '../api/rcApi.js';
import SocketioService from "../services/socketio.service.js";
import betaInviteForm from "../organisms/betaInviteForm.vue";
//import { iconStyle } from '../atoms/icon_styles';
export default {
  name: "topNavBar",
  components: {
    "c-navBar-mobile": navBarMobile,
    "c-navBar-desktop": navBarDesktop,
    //'c-icon': iconStyle,
    "c-name-div": nameDiv,
    "c-list-item": listItems,
    "c-row": rowDiv,
    //'c-profile-modal': profileModal,
    "c-top-navbar": topNavBarDiv,
    "c-logo-div": logoDiv,
    //'c-logo-text': logoText,
    "c-style-icon": style_icons,
    "c-search-bar": searchBar,
    "c-sign-in-buttons-div": signInButtonsDiv,
    "c-button-secondary": button_secondary,
    "c-button-primary": button_primary,
    "c-button-navlink": button_navlink,
    //'c-explore-button-div': exploreButtonDiv,
    //'c-frame-14': frame_14,
    //'c-icon-and-text-div': iconAndTextDiv,
    //'c-body-ux-14': body_UX_14,
    "c-search-box-div": searchBoxDiv,
    "c-input-search-box": inputSearchBox,
    //'c-nav-bar-div': navBarDiv,
    "c-profile-div": profileDiv,
    "c-H4-22": H4_22,
    //'c-H5-18': H5_18,
    "c-overline-11": OVERLINE_11,
    "c-frame-16": frame_16,
    "c-beta-invite-form": betaInviteForm,
    //'c-row': rowDiv
  },
  data() {
    return {
      notifications: [],
      defaultImage: profileImage,
      isAuth: false,
      collapsed: true,
      UI_baseURL: AppConfig.UI_Base_URL,
      auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
      get_acc_token: "",
      get_id_token: "",
      get_refresh_token: "",
      colorTheme: theme,
      //roleOfUser: "",
      username: "",
      inputFiltersList: "",
      routePathName: "",
      defaultNavBar: true,
      userImage: null,
      user_id: null,
      inputSearchedTerm: null,
      messagesRead: [],
      messagesDelivered: [],
      rocket_chat_live_api_obj: null,
      webSocketUrl: "wss://qroc.rasree.com/websocket",
      RC_Base_URL: AppConfig.Rocketchat_Base_URL,
      urgentNotificationView: false,
      urgentNofiticationInfo: {},
      msgList: [],
      roomName: "",
      isBetaInviteFormVisible: false,
    };
  },
  props: {
    //isAuth : Boolean,
    roleOfUser: String,
  },
  async beforeMount() {
    this.isAuth = this.$keycloak.authenticated;
    console.log("isauth", this.isAuth);
    //await this.primaryRoleOfUser()
    console.log("roleOfUser", this.roleOfUser);
    this.routePathName = window.location.pathname;
    console.log("route pathname", this.routePathName);
    // let subPathName = '/learn/session/'
    let liveSessionPathName = "/learn/live/";
    if (
      this.routePathName.includes(liveSessionPathName) ||
      this.routePathName.includes("/talk/")
    ) {
      this.defaultNavBar = false;
    }
    console.log("route", this.$route.name);
    if (this.$route.name === "browseSearch") {
      let explorebuttonStyle = document.getElementById("inputExploreBtn");
      console.log("explorebuttonStyle", explorebuttonStyle);
      explorebuttonStyle.style.color = theme["Primary-Cyan0400"];
      explorebuttonStyle.style.background = theme["Secondary-Gray0100"];
    }
    this.username = this.$keycloak["tokenParsed"]["given_name"];
    console.log("username", this.username);
    //this.userImage = this.$keycloak["tokenParsed"]["avatar"]
    //console.log("userImage", this.$keycloak["tokenParsed"]["avatar"]);
    const params = new URLSearchParams(window.location.search);
    if (params.has("term")) {
      // "term" exists, so we get its value
      this.inputSearchedTerm = params.get("term");
      //console.log("Key: term, Value:", this.inputSearchedTerm);
    }
    await this.getNotificationsListFn();
    await this.getRoomFunc();
    SocketioService.setupSocketConnection(this.$keycloak["token"]);
    SocketioService.socket.on("stream-messages", (data) => {
      console.log("get room messages in top nav bar", data);
      this.notifications.push(data.msg);
      if (data.msg.urgent_status === true) {
        this.urgentNofiticationInfo = data.msg;
        this.toggleUrgentAlertMenuModalFn();
      }
    });
    await this.joinRoomFunc();
    //if(message.fields.args[0].u.username === "rasreebot" && this.username === userInputMessage.to){
    //    if(userInputMessage.urgent_status === true){
    //        //this.urgentNotificationView = true
    //        this.urgentNofiticationInfo = userInputMessage
    //        //this.toggleUrgentAlertMenuModalFn()
    //    }
    //    //this.notifications.push(userInputMessage)
    //}
  },
  async mounted() {
    // fetch user avatar
    await this.getAvatarFn();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  methods: {
    /**
     * This function calls the openBetaInviteForm function of the betaInviteForm child
     * @openBetaInviteFormFn
     */
    openBetaInviteFormFn(){
      this.$refs.betaInviteForm.openBetaInviteForm()
    },
    async getRoomFunc() {
      this.user_id = this.$keycloak["tokenParsed"]["sub"];
      try {
        let getRoomNameResp = await axios.get(
          this.auth_api_Base_URL +
            "/v2_1/get-direct-rooms?req_user_id=" +
            this.user_id,
        );
        console.log("response from getRoomName", getRoomNameResp);
        this.roomName = getRoomNameResp.data.room_name;
      } catch {
        console.log("ERROR: Not able to get room name");
      }
    },
    async joinRoomFunc() {
      console.log(" room to join ", this.roomName);
      if (this.roomName !== "") {
        //await fetch(`${this.auth_api_Base_URL}/v2_1/joinRoom?room_name=${this.roomName}&joing_user_id=${this.$keycloak.tokenParsed.sub}`, {
        //	method: 'GET',
        //	headers: {
        //	'accept': 'application/json',
        //	'Authorization': 'Bearer ' + this.$keycloak.token,
        //	'Content-Type': 'application/json'
        //	},
        //});
        SocketioService.socket.emit("join-room", this.roomName, (resp) => {
          console.log("join room response", resp);
        });
      }
    },
    loginKCTokenFn() {
      let login_data = {
        serviceName: "keycloak",
        accessToken: this.$keycloak["token"],
        idToken: this.$keycloak["idToken"],
        expiresIn: this.$keycloak["tokenParsed"]["exp"],
      };
      console.log("login data", login_data);
      this.rocket_chat_live_api_obj.callMethod("login", login_data).subscribe(
        (resp) => {
          if (resp.msg === "result") {
            // success
            console.log("-- user is logged in ", resp.msg);
            this.getRoomsFn();
          }
        },
        (error) => {
          console.log("--", error);
        },
      );
    },
    getRoomsFn() {
      //console.log("functions in mediaChatApi are", this.mediaChatApi)
      this.rocket_chat_live_api_obj
        .callMethod("rooms/get", [{ $date: 1480377601 }])
        .subscribe(
          (mediaChatApiEvent) => {
            if (mediaChatApiEvent.msg === "result") {
              // success
              console.log("resp from get rooms:", mediaChatApiEvent);
              let roomsInfo = mediaChatApiEvent.result;
              let roomId = "";
              for (let idx = 0; idx < roomsInfo.length; idx++) {
                if (
                  roomsInfo[idx].name === undefined &&
                  roomsInfo[idx].usernames.includes("rasreebot")
                ) {
                  this.connectRoom(roomsInfo[idx]["_id"]);
                }
              }
              console.log("room id's", roomId);
            }
          },
          (error) => {
            this.errors.push(error);
          },
        );
    },
    connectRoom(roomId) {
      console.log("connect to room", roomId);
      this.rocket_chat_live_api_obj.sendMessage({
        msg: "sub",
        id: "" + new Date().getTime(),
        name: "stream-room-messages",
        params: [roomId, false],
      });
    },
    toggleAlertMenuModalFn() {
      const modal = document.getElementById("input-alert-menu-modal");
      if (modal.style.display === "none") {
        modal.style.display = "flex";
      } else {
        modal.style.display = "none";
      }

      window.onclick = (event) => {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    },
    toggleUrgentAlertMenuModalFn() {
      const urgentNotfModal = document.getElementById(
        "input-urgent-alert-menu-modal",
      );
      console.log("urgentNotfModal", urgentNotfModal.style.display);
      if (urgentNotfModal.style.display === "none") {
        urgentNotfModal.style.display = "flex";
      }
      window.onclick = (event) => {
        if (event.target === urgentNotfModal) {
          urgentNotfModal.style.display = "none";
        }
      };
    },
    navigateToConfirmPage(notf_info) {
      console.log("---urgent notf link info---", notf_info);
      if (notf_info.urgent_status === true) {
        window.location.href = notf_info.confirmation_link;
      }
    },
    closeUrgentPopUpModalFn() {
      const urgentNotfModal = document.getElementById(
        "input-urgent-alert-menu-modal",
      );
      console.log("urgentNotfModal", urgentNotfModal.style.display);
      if (urgentNotfModal.style.display === "flex") {
        urgentNotfModal.style.display = "none";
      }
    },
    async getNotificationsListFn() {
      try {
        const response = await axios.get(
          `${this.auth_api_Base_URL}/v3_1/notifications`,
          { params: { limit: 10 } },
        );
        this.notifications = response.data;
        this.messagesDelivered = [];
        for (let i = 0; i < this.notifications.length; i++) {
          this.messagesDelivered.push(this.notifications[i].id);
        }
        console.log("messagesDelivered", this.messagesDelivered);
        if (this.messagesDelivered.length > 0) {
          this.markAsDeliveredNotificationFn();
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    },
    async markAsReadNotificationFn() {
      try {
        const response = await axios.put(
          `${this.auth_api_Base_URL}/v3_1/mark-read`,
          this.messagesRead,
        );
        console.log("response", response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    },
    async markAsDeliveredNotificationFn() {
      try {
        const response = await axios.put(
          `${this.auth_api_Base_URL}/v3_1/mark-delivered`,
          this.messagesDelivered,
        );
        console.log("response", response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    },
    leaveEffect(event) {
      event.target.style.backgroundColor = "";
      event.target.style.color = "";
    },
    async navigateToInvitationPage(notification) {
      this.messagesRead = [];
      this.messagesRead.push(notification.id);
      await this.markAsReadNotificationFn();
      if (notification.tag === "pf-sponsorship-confirm") {
        window.location.href =
          this.UI_baseURL + "/manage/wardInvitation/" + notification.sw_id;
      } else if (notification.tag === "sp-sponsorship-confirm") {
        window.location.href =
          this.UI_baseURL + "/manage/sponsorInvitation/" + notification.sw_id;
      } else if (notification.tag === "tuto-conformation") {
        window.location.href =
          this.UI_baseURL + "/manage/orgInvitation/" + notification.sw_id;
      } else if (notification.tag === "org-conformation") {
        window.location.href =
          this.UI_baseURL + "/manage/tutorInvitation/" + notification.sw_id;
      } else {
        window.location.href = notification.confirmation_link;
      }
    },
    viewAllNotificationsFn() {
      window.location.href = this.UI_baseURL + "/notifications";
    },
    highlightItem(event) {
      event.target.style.backgroundColor = "grey";
      event.target.style.color = "white";
    },
    unhighlightItem(event) {
      event.target.style.backgroundColor = "";
      event.target.style.color = "";
    },
    myCoursesFn() {
      //let type = 'courses'
      let myCourseUrl = this.UI_baseURL + "/search-course";
      console.log("myCourse url", myCourseUrl);
      window.location.href = myCourseUrl;
    },
    mySessionsFn() {
      //let type = 'sessions'
      // let mySessionsUrl = this.UI_baseURL + '/browseSearch?type=' + type + '&enrolled=Show+only+my+courses'
      let mySessionsUrl = this.UI_baseURL + "/search-slot";
      console.log("mySessions url", mySessionsUrl);
      window.location.href = mySessionsUrl;
    },
    myChallengesFn() {
      let myChallengesURL = this.UI_baseURL + "/search-challenge";
      console.log("myChallenges url", myChallengesURL);
      window.location.href = myChallengesURL;
    },
    manageOrgFn() {
      let manageOrgUrl = this.UI_baseURL + "/orgs/my";
      console.log("manageOrg url", manageOrgUrl);
      window.location.href = manageOrgUrl;
    },
    manageUsersFn() {
      let manageUserUrl = this.UI_baseURL + "/manage-user";
      console.log("manageUser url", manageUserUrl);
      window.location.href = manageUserUrl;
    },
    manageSposorFn() {
      let manageSponsorPageUrl = this.UI_baseURL + "/manage/sponsor";
      console.log("manageSponsorPage url", manageSponsorPageUrl);
      window.location.href = manageSponsorPageUrl;
    },
    manageWardFn() {
      let manageWardPageUrl = this.UI_baseURL + "/manage/ward";
      console.log("manageWardPage url", manageWardPageUrl);
      window.location.href = manageWardPageUrl;
    },
    getAlertMenuViewFn() {
      //Define display of alert menu view here
    },
    gotoCalendarFn() {
      this.user_id = this.$keycloak["tokenParsed"]["sub"];
      let calendarPageUrl = this.UI_baseURL + "/calendar/" + this.user_id;
      console.log("calendarPage url", calendarPageUrl);
      window.location.href = calendarPageUrl;
    },
    navigateToBrowseSearchFn() {
      let browseSearchUrl = this.UI_baseURL + "/search";
      console.log("browseSearch url", browseSearchUrl);
      window.location.href = browseSearchUrl;
    },
    navigateToProfilePageFn() {
      let profilePageUrl = this.UI_baseURL + "/profile";
      console.log("profilePage url", profilePageUrl);
      window.location.href = profilePageUrl;
    },
    enrollFn() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//embed.typeform.com/next/embed.js";
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    getProfileMenuViewFn() {
      // Get the modal
      let profileMenuViewElem = document.getElementById("inputProfileMenuView");

      // When the user clicks the button, open the modal
      // we can use any display property, which can display element
      // dont use none property, because it will not display it
      profileMenuViewElem.style.display = "flex";

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        // console.log("event", event.target);
        if (event.target == profileMenuViewElem) {
          profileMenuViewElem.style.display = "none";
        }
      };
    },
    /**
     *
     * @deprecated This function is deprecated and it can be removed.
     * We are not using this API calls any more
     */
    async primaryRoleOfUser() {
      console.log("----");
      if (localStorage.getItem("access-token") != null) {
        this.get_acc_token = localStorage.getItem("access-token");
        this.get_id_token = localStorage.getItem("id-token");
        this.get_refresh_token = localStorage.getItem("refresh-token");
      }
      try {
        let allRolesResp = await axios.get(
          this.auth_api_Base_URL + "/v1_0/me/primary_role",
          {
            headers: {
              Authorization: "Bearer " + this.get_acc_token,
              "id-token": this.get_id_token,
              "access-token": this.get_acc_token,
              "refresh-token": this.get_refresh_token,
            },
          },
        );
        console.log("get primary role", allRolesResp);
        /* eslint-disable */
        this.roleOfUser = allRolesResp.data.primary_role;
      } catch (error) {
        console.log("error in resp pf primary role:", error.response);
        this.showRespMsg = true;
        this.respMsg = error.response.headers["message"];
      }
    },
    logoutFn() {
      //let logoutURL = this.UI_baseURL + '/logout'
      //console.log("keycloak logout", this.$keycloak)
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("id-token");
      this.$keycloak.logout({ redirectUri: this.UI_baseURL });
      //window.location.href = logoutURL
    },
    gotoSignedHomeFn() {
      //here, we are pushing current path to history. If user hits back button, it will redirects to previous page
      let newurl = window.location.href;
      window.history.pushState({ path: newurl }, "", newurl);
      console.log("---newurl--", newurl);
      let redirect_to = window.location.href;
      this.$keycloak.login({ redirectUri: redirect_to });
      //let signedHomeUrl = this.UI_baseURL + '/login'
      //console.log("signedHome url", signedHomeUrl)
      //window.location.href = signedHomeUrl
    },
    gotoProfileInfoFn() {
      let profileUrl = this.UI_baseURL + "/profile";
      console.log("profile url", profileUrl);
      window.location.href = profileUrl;
    },
    gotoHomePageFn() {
      //@soma : jredirect to base url
      window.location = this.UI_baseURL + "/";
    },
    gotoBrowseSearchFn(searchedTerm) {
      console.log("searchedTerm", searchedTerm);
      this.inputSearchedTerm = searchedTerm;
      this.routePathName = window.location.pathname;
      console.log("routePathName", this.routePathName);
      if (this.routePathName === "/search") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/search") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/search";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/search";
          } else {
            browseSearchURL =
              this.UI_baseURL + "/search?term=" + this.inputSearchedTerm;
          }
          console.log("browseSearch url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/search-slot") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/search-slot") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/search-slot";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/search-slot";
          } else {
            browseSearchURL =
              this.UI_baseURL + "/search-slot?term=" + this.inputFiltersList;
          }
          console.log("browseSearch of slot url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/search-course") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/search-course") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/search-course";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          console.log("inputSearchedTerm", inputSearchedTerm);
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            browseSearchURL = this.UI_baseURL + "/search-course";
          } else {
            browseSearchURL =
              this.UI_baseURL + "/search-course?term=" + this.inputSearchedTerm;
          }
          console.log("browseSearch of course url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/search-challenge") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/search-challenge") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/search-challenge";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/search-challenge";
          } else {
            browseSearchURL =
              this.UI_baseURL +
              "/search-challenge?term=" +
              this.inputSearchedTerm;
          }
          console.log("browseSearch of challenge url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/search-org") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/search-org") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/search-org";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/search-org";
          } else {
            browseSearchURL =
              this.UI_baseURL + "/search-org?term=" + this.inputSearchedTerm;
          }
          console.log("browseSearch of org url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/add-challenge-to-playlist") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/add-challenge-to-playlist") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/add-challenge-to-playlist";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/add-challenge-to-playlist";
          } else {
            browseSearchURL =
              this.UI_baseURL +
              "/add-challenge-to-playlist?term=" +
              this.inputSearchedTerm;
          }
          console.log("browseSearch of challenge url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else if (this.routePathName === "/get-user-playlist") {
        let browseSearchURL = window.location.href;
        if (window.location.pathname === "/get-user-playlist") {
          if (searchedTerm === undefined) {
            browseSearchURL = this.UI_baseURL + "/get-user-playlist";
            window.location.href = browseSearchURL;
            return;
          }
          // keep the already existing filters
          const url = window.location.href;
          console.log("url", url);
          let r = new URL(url);
          r.searchParams.delete("term"); //it will remove search_text filter only
          const newUrl = r.href;
          window.history.pushState({ path: newUrl }, "", newUrl);
          if (
            history.pushState &&
            this.inputSearchedTerm !== undefined &&
            this.inputSearchedTerm !== "" &&
            this.inputSearchedTerm !== null
          ) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("term", this.inputSearchedTerm);
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?" +
              searchParams.toString();
            window.location.href = newurl;
          }
        } else {
          if (
            this.inputSearchedTerm === undefined ||
            this.inputSearchedTerm === "" ||
            this.inputSearchedTerm === null
          ) {
            console.log("this.router", this.$router);
            //this.$router.push({ path: '/browseSearch'})
            browseSearchURL = this.UI_baseURL + "/get-user-playlist";
          } else {
            browseSearchURL =
              this.UI_baseURL +
              "/get-user-playlist?term=" +
              this.inputSearchedTerm;
          }
          console.log("browseSearch of challenge url", browseSearchURL);
          window.location.href = browseSearchURL;
        }
      } else {
        console.log("--browse search--");
        let browseSearchPageURL = "";
        if (
          this.inputSearchedTerm === undefined ||
          this.inputSearchedTerm === "" ||
          this.inputSearchedTerm === null
        ) {
          console.log("this.router", this.$router);
          browseSearchPageURL = this.UI_baseURL + "/search";
        } else {
          browseSearchPageURL =
            this.UI_baseURL + "/search?term=" + this.inputSearchedTerm;
        }
        this.inputSearchedTerm = "";
        console.log("browseSearchPageURL url", browseSearchPageURL);
        window.location.href = browseSearchPageURL;
      }
    },
    switchToLearnerDashBoardFn() {
      //this.roleOfUser = "Protege"
      //this.primaryRoleSelection()
      let learnerDashBoardUrl = this.UI_baseURL + "/dashBoard";
      console.log("learnerDashBoard url", learnerDashBoardUrl);
      window.location.href = learnerDashBoardUrl;
    },
    switchToAdminDashBoardFn() {
      let learnerDashBoardUrl = this.UI_baseURL + "/admin/dashBoard";
      console.log("learnerDashBoard url", learnerDashBoardUrl);
      window.location.href = learnerDashBoardUrl;
    },
    async getAvatarFn() {
      try {
        let resp = await axios
          .get(
            this.auth_api_Base_URL +
              "/v3_1/get_avatar?req_user_id=" +
              this.$keycloak["tokenParsed"]["sub"],
            {
              responseType: "arraybuffer",
            },
          )
          .then((response) => {
            console.log("response from user image", response);
            let image = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                "",
              ),
            );
            let imageUrl = `data:${response.headers["content-type"].toLowerCase()};base64,${image}`;
            this.userImage = imageUrl;
          });
        console.log("get avatar Image", resp);
      } catch (error) {
        console.log("error in resp of get Avatar:", error.response);
      }
    },
    /**
     *
     * @deprecated This function is deprecated and it can be removed.
     * We are not using this API calls any more
     */
    async primaryRoleSelection() {
      console.log("----");
      if (localStorage.getItem("access-token") != null) {
        this.get_acc_token = localStorage.getItem("access-token");
        this.get_id_token = localStorage.getItem("id-token");
        this.get_refresh_token = localStorage.getItem("refresh-token");
      }
      try {
        let apiData = { primary_role: this.roleOfUser };
        let selectionResp = await axios.post(
          this.auth_api_Base_URL + "/v1_0/me/primary_role",
          apiData,
          {
            headers: {
              Authorization: "Bearer " + this.get_acc_token,
              "id-token": this.get_id_token,
              "access-token": this.get_acc_token,
              "refresh-token": this.get_refresh_token,
            },
          },
        );
        console.log("get selected role resp", selectionResp);
        //this.selectedRole = selectionResp.data
        let learnerDashBoardUrl = this.UI_baseURL + "/dashBoard";
        console.log("learnerDashBoard url", learnerDashBoardUrl);
        window.location.href = learnerDashBoardUrl;
      } catch (error) {
        console.log("error in resp of selected role:", error.response);
        this.showRespMsg = true;
        this.respMsg = error.response.headers["message"];
      }
    },
  },
};
</script>

<style scoped>
.notification-bell {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  order: 2;
  font-size: 4px; /* Adjust size as needed */
}

.notification-count {
  position: absolute;
  top: -4px; /* Adjust position as needed */
  right: -6px; /* Adjust position as needed */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 6px; /* Adjust padding as needed */
  font-size: 10px; /* Adjust font size as needed */
  line-height: 15px; /* Adjust line height to match the height for a circle shape */
}
#input-alert-menu-modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

#input-urgent-alert-menu-modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

#input-alert-menu-view {
  background-color: #f8ffff;
  border: 1px solid #d2e7e7;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: block;
  width: 600px;
  position: fixed;
  right: 15%;
  top: 87px;
  padding: 4px;
}
#input-urgent-alert-menu-view {
  background-color: #f8ffff;
  border: 1px solid #d2e7e7;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  right: 0px;
  width: auto;
  position: fixed;
  left: 30% !important;
  right: 30% !important;
  top: 87px;
  padding: 4px;
}

#ntf-list-div div:hover {
  background-color: grey;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

#ntf-list-div {
  text-align: left;
}

#ntf-view-more-div button {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #00b0b2;
  flex: none;
  order: 9;
  align-self: stretch;
  flex-grow: 1;
}
</style>
<style>
.is-active.router-link-active {
  text-decoration: none;
  border: 1px solid #fff;
  color: rgb(233, 23, 23) !important;
}
.v-application p {
  margin-bottom: 0px !important;
}
.v-application a {
  color: #586666 !important;
  text-decoration: none !important;
}
a {
  text-decoration: none;
}

.profilemodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /*filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));*/
}

/* Modal Content */
.profile-menu-view {
  background-color: #f8ffff;
  border: 1px solid #d2e7e7;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: block;
  right: 0px;
  bottom: 0px;
  width: auto;
  position: fixed;
  right: 10px;
  top: 87px;
}
@media (max-width: 860px) {
  #nav-desktop {
    display: none;
  }
  #nav-mobile {
    display: flex;
    height: 32px;
  }
}
</style>

